@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");

* {
  font-family: "Changa", sans-serif;
}

body {
  /* font-family: Roboto, Helvetica, Arial, sans-serif;*/
  font-family: "Aref Ruqaa Ink", serif;
  background-color: #fbfbfb;
}

.main-nav {
  /* background-color: #08050c7d; */
  background-color: #08050ca6;
  transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
}

.main-nav .nav-link {
  color: #fff !important;
}

.main-nav .nav-link:hover,
.main-nav .nav-link:focus {
  color: #f58034 !important;
}

.track {
  padding-top: 10.5rem;
  padding-bottom: 6rem;
  text-align: center;
  height: 70vh;
  color: #fff;
  /* background-image: url("../assets/img/header-bg.webp"); */
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.track-form {
  background-color: #08050c7d;
  padding: 1rem;
  border-radius: 1rem;
  color: #fff;
}

.section {
  padding: 5rem 1rem;
  background-color: #fff;
}
.section-dark {
  padding: 5rem 1rem;
  color: #fff;
  background-color: #212529;
}
.section-heading {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.section-subtitle {
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 4rem;
}

.service-card-img {
  width: 65% !important;
  height: 150px;
  margin: auto;
  padding: 10px;
}

.marquee {
  height: 6rem;
  overflow: hidden;
  position: relative;
  /*background: #fefefe;*/
  color: #333;
  /*border: 1px solid #4a4a4a;*/
}

.marquee .marquee-col {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 3s linear infinite;
  -webkit-animation: scroll-left 3s linear infinite;
  animation: scroll-left 25s linear infinite;
}
.marquee-item {
  display: inline-block;
}
@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.marquee {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  border-radius: 15%;
  overflow: hidden;
  /* background: white; */
  white-space: nowrap;
}

.marquee-inner {
  display: block;
  width: 200%;
  position: absolute;
  animation: marquee 15s linear infinite;
}

.marquee-inner:hover {
  animation-play-state: paused;
}

span {
  float: left;
  width: 50%;
  overflow: hidden;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@-moz-keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@-webkit-keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.bg-map {
  background-color: #212529;
  background-image: url("./assets/img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;
}

.language-nav {
  width: 20% !important;
}
.language-menu {
  background-color: #212529c4 !important;
  color: white;
  /* text-align: center; should be dynamic */
  max-width: fit-content;
}

.language-menu .dropdown-item {
  color: white !important;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
}

.language-menu .dropdown-item:hover {
  color: #f47b32 !important;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem;
}
